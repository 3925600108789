import { RoutePattern } from "../../routes/RoutePattern";
import ALBUM_COVER from "../../assets/images/album.webp";
import BG_DESKTOP from "../../assets/images/release.desktop.webp";
import BG_MOBILE from "../../assets/images/release.mobile.webp";
import HUES_TEXT from "../../assets/images/hues.png";

const data = [
  {
    img: ALBUM_COVER,
    to: "https://vyd.co/Hues",
    title: "hues",
    artist: "NIKO BRIM",
    title_img: HUES_TEXT,
  },
  {
    img: "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/cc/57/fa/cc57faf2-c669-e255-3c9d-e9a2eb6e5e75/62269.jpg/512x512bb.jpg",
    to: "https://linktr.ee/eleven53cyn",
    title: "Eleven53",
    artist: "Kai Ca$h & Niko Brim",
  },
  {
    img: "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/ce/f6/5e/cef65ea8-2120-a6d4-b79e-fe3889626f6c/2074.jpg/512x512bb.jpg",
    to: "https://linktr.ee/b4thethrone",
    title: "B4THETHRONE",
    artist: "Niko Brim",
  },
  {
    img: "https://is1-ssl.mzstatic.com/image/thumb/Music118/v4/5f/53/4b/5f534b7b-402d-308a-8d41-9195f20d47e7/192641012518_Cover.jpg/512x512bb.jpg",
    to: "https://linktr.ee/athousandpicturesbooktwo",
    title: "A Thousand Pictures: Book Two",
    artist: "Niko Brim",
  },
  {
    img: "https://is1-ssl.mzstatic.com/image/thumb/Music118/v4/ea/c7/25/eac72506-9325-3f6c-9ab2-588bc5d2daff/192641079030_Cover.jpg/512x512bb.jpg",
    to: "https://linktr.ee/athousandpicturesbookone",
    title: "A Thousand Pictures: Book One",
    artist: " Niko Brim",
  },
];

const Releases = () => {
  return (
    <div
      id={RoutePattern.RELEASES}
      className="text-dark relative flex flex-col justify-center items-center"
    >
      <div className="w-full h-screen md:h-[70vh] relative">
        <img
          className="object-cover object-top w-full h-full"
          src={window.innerWidth >= 768 ? BG_DESKTOP : BG_MOBILE}
          alt="desktop"
        />
        <h3 className="position-center heading-3">RELEASES</h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[2rem] py-[7rem]  mx-auto w-5/6 md:w-3/4">
        {data.map((item, idx) => (
          <div
            key={idx}
            className="flex flex-col gap-[1rem] mx-auto h-full w-full"
          >
            <div className="w-full">
              <img
                className="w-full h-full object-cover"
                src={item.img}
                alt="title"
              />
            </div>
            <div className="relative w-full h-full flex flex-col justify-center items-center text-center">
              {/* <span className="uppercase tracking-widest">releases</span> */}
              {item.title === "hues" ? (
                <img
                  className="my-[.75rem] block w-[15%] md:w-[15%]"
                  src={item.title_img}
                />
              ) : (
                <h4 className="font-secondary text-ellipsis text-nowrap overflow-hidden w-full text-[1.4rem] md:text-[3rem]">
                  {item.title}
                </h4>
              )}

              <span className="tracking-widest text-ellipsis text-nowrap overflow-hidden w-full">
                {item.artist}
              </span>
              <div className="mt-[2rem]">
                <a
                  href={item.to}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-secondary py-[1rem] px-[1.5rem] uppercase font-primary leading-[100%] block hover:bg-primary"
                >
                  listen now
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Releases;
