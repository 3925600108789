import { useEffect, useState } from "react";

interface PopupProps {
  className?: string;
  children?: any;
}

const Popup = ({ className, children }: PopupProps) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2000);
  }, []);

  return (
    <div
      className={`flex left-0 fixed w-full h-screen transition-all ${
        open ? "top-0 opacity-100" : "-top-[120%] md:-top-ful opacity-0"
      }`}
      style={{ zIndex: 123123123123 }}
    >
      <div className="bg-white flex justify-center items-center relative w-[90%] md:w-2/5 h-1/2 m-auto rounded-md overflow-hidden shadow-md">
        <button
          className="absolute top-5 right-8"
          onClick={() => setOpen(false)}
        >
          X
        </button>
        {children}
      </div>
    </div>
  );
};

export default Popup;
