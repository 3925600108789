import Newsletter from "../../components/Newsletter/Newsletter";
import Popup from "../Popup/Popup";
import Menu from "./Menu/Menu";
import Showcase from "./Showcase/Showcase";

const Home = () => {
  return (
    <div className="relative flex flex-col justify-center items-center">
      <Showcase />
      <Menu />
      <Popup>
        <Newsletter />
      </Popup>
    </div>
  );
};

export default Home;
