import { RoutePattern } from "../../routes/RoutePattern";
import ALBUM_COVER from "../../assets/images/album.webp";
import BG_DESKTOP from "../../assets/images/merch.desktop.webp";
import BG_MOBILE from "../../assets/images/merch.mobile.webp";
import { useEffect } from "react";

const data = [
  {
    img: ALBUM_COVER,
    to: "#",
    title: "harley",
    price: "$100",
  },
  {
    img: ALBUM_COVER,
    to: "#",
    title: "spirit",
    price: "$100",
  },
  {
    img: ALBUM_COVER,
    to: "#",
    title: "make it home",
    price: "$100",
  },
  {
    img: ALBUM_COVER,
    to: "#",
    title: "honey",
    price: "$100",
  },
];

export default function Merch() {
  useEffect(() => {
    const w = window as any;
    // w.showProducts();
    w.showProducts2();
    w.showProducts3();
    w.showProducts4();
  }, []);

  return (
    <div
      id={RoutePattern.SHOP}
      className="text-dark relative flex flex-col justify-center items-center "
    >
      <div className="w-full h-screen md:h-[70vh] relative">
        <img
          className="object-cover object-top w-full h-full"
          src={window.innerWidth >= 768 ? BG_DESKTOP : BG_MOBILE}
          alt="desktop"
        />
        <h3 className="position-center heading-3">merch</h3>
      </div>
      <div className="flex justify-center items-end m-auto flex-col gap-x-[2vw] md:flex-row py-[7rem] mx-auto w-5/6 md:w-3/4">
        <div id="product-component-1721850950138" />
        <div id="product-component-1721850708529" />
        <div id="product-component-1718312765019" />

        {/* not working */}
        {/* <div id="product-component-1717087681025" /> */}
      </div>
      {/* <div className="grid grid-cols-2 md:grid-cols-4 gap-[2rem] py-[7rem]  mx-auto w-5/6 md:w-3/4">
        {data.map((item, idx) => (
          <div
            key={idx}
            className="flex flex-col gap-[1rem] mx-auto h-full w-full"
          >
            <div className="w-full">
              <img
                className="w-full h-full object-cover"
                src={item.img}
                alt="title"
              />
            </div>
            <div className="relative w-full h-full flex flex-col justify-center items-center text-center">
              <h4 className="uppercase text-[1rem] md:text-[1.5rem]">
                {item.title}
              </h4>
              <span className="">{item.price}</span>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
}
