import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  isBurgerActive: false,
  isLoaded: false,
  currentVideoPopup: null,
  youtubeItems: [],
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsBurgerActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isBurgerActive = payload;
    },
    setCurrentVideoPopup: (state, { payload }) => {
      state.currentVideoPopup = payload;
    },
    setYoutubeItems: (state, { payload }) => {
      state.youtubeItems = payload;
    },
  },
});

export const { setIsBurgerActive, setCurrentVideoPopup, setYoutubeItems } =
  globalSlice.actions;
