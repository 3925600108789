import { RoutePattern } from "../../routes/RoutePattern";

interface RouteTypes {
  name: string;
  to?: string;
  smooth?: boolean;
  isAnchor?: boolean;
}

export const data: RouteTypes[] = [
  {
    name: "home",
    to: "/",
  },
  {
    name: RoutePattern.RELEASES,
    to: "/" + RoutePattern.RELEASES,
  },
  {
    name: RoutePattern.VIDEOS,
    to: "/" + RoutePattern.VIDEOS,
  },
  {
    name: RoutePattern.TOUR,
    to: "/" + RoutePattern.TOUR,
  },
  {
    name: RoutePattern.SHOP,
    to: "/" + RoutePattern.SHOP,
  },
  // {
  //   name: RoutePattern.SOCIAL,
  //   smooth: true,
  // },
];
