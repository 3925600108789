import useScriptService from "../../../Hooks/useScriptService";
import BG_DESKTOP from "../../../assets/images/tour.desktop.webp";
import BG_MOBILE from "../../../assets/images/tour.mobile.webp";
import { RoutePattern } from "../../../routes/RoutePattern";

const Tour = () => {
  useScriptService({ url: "https://widget.bandsintown.com/main.min.js" });

  return (
    <div
      id={RoutePattern.TOUR}
      className="text-dark relative flex flex-col justify-center items-center"
    >
      <div className="w-full h-screen md:h-[70vh] relative">
        <img
          className="object-cover object-top w-full h-full"
          src={window.innerWidth >= 768 ? BG_DESKTOP : BG_MOBILE}
          alt="desktop"
        />
        <h3 className="position-center heading-3">TOUR</h3>
      </div>
      <div className="py-[7rem]  mx-auto w-5/6 md:w-3/4">
        <a
          className="bit-widget-initializer"
          data-artist-name="ID_15412202"
          data-display-local-dates="false"
          data-display-past-dates="false"
          data-auto-style="false"
          data-text-color="#000000"
          data-link-color="#0435C2"
          data-background-color="rgba(0,0,0,0)"
          data-display-limit="15"
          data-display-start-time="false"
          data-link-text-color="#FFFFFF"
          data-display-lineup="false"
          data-display-play-my-city="true"
          data-separator-color="rgba(124,124,124,0.25)"
        ></a>
      </div>
    </div>
  );
};

export default Tour;
