import { Link } from "react-router-dom";
import ALBUM_COVER from "../../../assets/images/album.webp";
import VIDEO_COVER from "../../../assets/images/video.webp";
import VINYL_COVER from "../../../assets/images/vinyl.webp";
import { RoutePattern } from "../../../routes/RoutePattern";
import { YoutubePopup } from "../../../components";
import { useState } from "react";
import { YoutubeItemTypes } from "../../../components/YoutubeSlider/types";
import HUEST_TEXT from "../../../assets/images/hues.png";

const data = [
  {
    img: ALBUM_COVER,
    to: "/releases",
    type: "releases",
    details: {
      title: "releases",
      description: "NIKO BRIM",
      text: "hues",
      buttonText: "view all releases",
      img: HUEST_TEXT,
    },
    buttonInfo: {
      link: "https://vyd.co/Hues",
      text: "listen now",
    },
  },
  {
    img: "https://i3.ytimg.com/vi/vo44Uz6JomY/maxresdefault.jpg",
    to: "/videos",
    type: "videos",
    details: {
      title: "videos",
      description: "NIKO BRIM ft. K. WALES & KAI CA$H",
      text: "4dacompany",
      buttonText: "view all videos",
    },
    buttonInfo: {
      link: "https://www.youtube.com/watch?v=vo44Uz6JomY",
      text: "watch now",
    },
  },
  // {
  //   img: VINYL_COVER,
  //   to: "/shop",
  //   details: {
  //     title: "SHOP",
  //     description: "NIKO BRIM",
  //     text: "vinyl",
  //   },
  // },
];

export default function Menu() {
  const [youtubeItem, setYoutubeItems] = useState<YoutubeItemTypes[]>([
    {
      title:
        "Niko Brim - 4DaCompany (Feat. K. Wales & Kai Ca$h) [Official Music Video]",
      videoId: "vo44Uz6JomY",
    },
  ]);
  const [currentId, setCurrentId] = useState<number | null>(null);

  return (
    <div
      id={RoutePattern.RELEASES}
      className="text-dark relative flex flex-col justify-center items-center w-full py-[7rem]"
    >
      <div className="flex flex-col gap-[2rem]">
        {data.map((item, idx) => (
          <div
            key={idx}
            className="flex flex-col md:flex-row gap-[1rem] mx-auto h-auto md:h-[30rem] md:w-3/4 w-5/6"
          >
            <div className="w-full h-[15rem] md:h-full">
              <img
                className="w-full h-full md:h-full object-cover"
                src={item.img}
                alt="title"
              />
            </div>
            <div className="relative w-full py-[1rem] h-auto md:h-full flex flex-col justify-center items-center text-center border-primary border-[.2rem] hover:bg-secondary hover:bg-opacity-50">
              <span className="uppercase tracking-widest md:absolute md:top-[10%]">
                {item.details.title}
              </span>
              {item.details.text === "hues" ? (
                <img className="w-[22%]" src={item.details.img} />
              ) : (
                <h4 className="font-secondary text-[2rem] mt-[1rem] md:mt-0 md:text-[3rem]">
                  "{item.details.text}"
                </h4>
              )}

              <span>{item.details.description}</span>
              <div className="mt-[1rem] md:mt-[2rem]">
                {item.type.includes("videos") ? (
                  <button
                    onClick={() => {
                      setCurrentId(0);
                    }}
                    className="w-full bg-secondary py-[1rem] px-[1.5rem] uppercase font-primary leading-[100%] block hover:bg-primary"
                  >
                    {item.buttonInfo.text}
                  </button>
                ) : (
                  <a
                    href={item.buttonInfo.link}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-secondary py-[1rem] px-[1.5rem] uppercase font-primary leading-[100%] block hover:bg-primary"
                  >
                    {item.buttonInfo.text}
                  </a>
                )}

                <Link
                  to={item.to}
                  className="mt-[1rem] bg-secondary py-[1rem] px-[1.5rem] uppercase font-primary leading-[100%] block hover:bg-primary"
                >
                  {item.details.buttonText}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* popup for homepage */}
      <YoutubePopup
        currentId={currentId}
        setCurrentId={setCurrentId}
        data={youtubeItem}
      />
    </div>
  );
}
