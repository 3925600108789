import BG_DESKTOP from "../../../assets/images/bg.desktop.webp";
import BG_MOBILE from "../../../assets/images/bg.mobile.webp";
import { SocialIcons } from "../../../components";
import { RoutePattern } from "../../../routes/RoutePattern";
import { CriOS, iOS } from "../../../util";

const Showcase = () => {
  return (
    <div
      id={RoutePattern.HOME}
      className={`
   ${CriOS ? "h-[88vh]" : iOS ? "h-[91vh]" : "h-[93vh]"} relative md:h-screen flex flex-col justify-center items-center w-full`}
    >
      <div className="w-full h-full">
        <img
          className="object-cover object-top w-full h-full"
          src={window.innerWidth >= 768 ? BG_DESKTOP : BG_MOBILE}
          alt="desktop"
        />
        <div className="absolute left-1/2 transform -translate-x-1/2 bottom-[5%]">
          <SocialIcons />
        </div>
      </div>
    </div>
  );
};

export default Showcase;
