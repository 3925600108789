import { YoutubeItemTypes } from "../YoutubeSlider/types";

interface PopupProps {
  className?: string;
  currentId: number | null;
  setCurrentId: (e: number | null) => void;
  data: YoutubeItemTypes[];
}

const YoutubePopup = ({ currentId, setCurrentId, data }: PopupProps) => {
  if (currentId === null) return;

  console.log(data[currentId]);

  return (
    <div
      className={`flex left-0 fixed w-full h-screen transition-all ${
        currentId !== null ? "top-0 opacity-100" : "-top-full opacity-0"
      }`}
      style={{ zIndex: 123123123123 }}
      // onClick={(e) => {
      //   setOpen(e.currentTarget !== e.target);
      // }}
    >
      <div className="relative w-[3/4] md:w-2/5 m-auto  bg-white border-[.2rem] border-primary p-[.2rem] md:p-[1rem]">
        <button
          className="absolute top-5 right-0 md:-right-[7%] bg-primary py-[.5rem] px-[1rem] text-white"
          onClick={() => setCurrentId(null)}
        >
          X
        </button>
        <div className="flex flex-col">
          <div>
            <iframe
              className="w-full h-[30vh] md:h-[40vh]"
              src={`https://www.youtube.com/embed/${data[currentId].videoId}?si=S3JmcJs43g1HZaz4`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>
          <div className="relative  h-full flex flex-col justify-center items-center text-center w-full">
            <h4 className="font-secondary text-[1rem] uppercase  w-full md:text-[3rem]">
              {data[currentId].title}
            </h4>
            {/* <span className="tracking-widest">{data[currentId].}</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YoutubePopup;
