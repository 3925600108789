interface BurgerProps {
  isActive: boolean;
  setIsActive: (e: boolean) => void;
}

export default function Burger({ isActive, setIsActive }: BurgerProps) {
  return (
    <div
      className="flex flex-col z-50 cursor-pointer mr-5 md:hidden"
      aria-label="button"
      onClick={() => {
        setIsActive(!isActive);
      }}
    >
      <div
        className={`w-6 h-0.5 md:h-1 bg-white rounded-sm ${
          isActive ? "rotate-45" : "rotate-0"
        } transition-all`}
      />
      <div
        className={`w-6 h-0.5 md:h-1 bg-white rounded-sm mt-1 ${
          isActive ? "hidden" : "block"
        }`}
      />
      <div
        className={`w-6 h-0.5 md:h-1 bg-white rounded-sm ${
          isActive ? "-rotate-45 absolute" : "rotate-0 mt-1"
        } transition-all`}
      />
    </div>
  );
}
