import { Field, Form, Formik, useField } from "formik";
import useEmailSubscribe, {
  error,
  success,
} from "../../Hooks/useEmailSubscribe";

const Newsletter = () => {
  const { submitForm } = useEmailSubscribe();

  return (
    <div className="w-5/6 md:w-1/2 mx-auto text-center">
      <h2 className="heading-2">subscribe</h2>
      <span className="mb-[2rem] block">
        Be the first to know about new releases and exclusive offers.
      </span>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={async (values, { resetForm }) => {
          try {
            const submitted = await submitForm(values);
            console.log(values);

            if (submitted) {
              success({ titleText: "Thank you for subscribing" });
              resetForm();
            }
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-row justify-center items-center">
            <Field
              className="mr-[1rem] py-[.55rem] px-[1rem] bg-transparent border-[.2rem] border-primary w-full outline-none focus:outline-none"
              type="email"
              name="email"
              placeholder="EMAIL"
            />
            <button
              className={`uppercase py-[.55rem] px-[1rem] border-[.2rem] bg-primary border-primary whitespace-nowrap text-white
              `}
              type="submit"
            >
              {isSubmitting ? "submitting" : "subscribe"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Newsletter;
