import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { data } from "./data";
import styled from "styled-components";

const SocialLink = styled.a`
  width: 1.2rem;
  :not(:last-child) {
    margin-right: 0.8rem;
  }
`;

interface SocialIconProps {
  pandoraIconWidth?: string;
  iconWidth?: string;
}

export default function SocialIcons({
  iconWidth,
  pandoraIconWidth,
}: SocialIconProps) {
  return (
    <ul className="flex text-white flex-row justify-center items-center gap-x-[4vh]">
      {data.map((item, idx) => (
        <SocialLink
          key={idx}
          href={item.link}
          rel="noreferrer"
          target="_blank"
          className=""
        >
          {item.icon ? (
            <FontAwesomeIcon icon={item.icon as any} />
          ) : item.isPandora ? (
            <img src={item.img} className={pandoraIconWidth} />
          ) : (
            <img src={item.img} className={iconWidth} />
          )}
        </SocialLink>
      ))}
    </ul>
  );
}
