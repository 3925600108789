import { data } from "./data";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import LOGO_IMG from "../../assets/images/logo.webp";
import Burger from "./Burger";

const Container = styled.div`
  z-index: 20;
`;

export default function Navigation() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { currentRoute } = useSelector((state: RootState) => state.Routes);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      const scrollY = window.scrollY;
      if (scrollY >= 20) {
        setScrolled(true);
      } else if (scrollY < 20) {
        setScrolled(false);
      }
    };
  }, []);

  const menuItems = data.map((item, idx) =>
    // this statement is application only if we're at home
    item.smooth && currentRoute === "/" ? (
      <button
        key={idx}
        onClick={() => {
          setIsActive(false);

          document.getElementById(item.name)?.scrollIntoView({
            behavior: "smooth",
          });
        }}
        className="link"
      >
        {item.name}
      </button>
    ) : item.to ? (
      <Link
        to={item.to}
        onClick={() => {
          setIsActive(false);
        }}
        className="link"
      >
        {item.name}
      </Link>
    ) : (
      <a
        key={idx}
        href={item.to} // route
        onClick={() => setIsActive(false)}
        className="link"
      >
        {item.name}
      </a>
    )
  );

  return (
    <Container
      className={`${
        scrolled ? "fixed shadow-sm bg-primary" : "fixed bg-none"
      } font-secondary mx-auto flex flex-col justify-center items-center text-white px-5 top-0 left-0 transition-all duration-500 w-full z-50 lg:px-[5rem]`}
    >
      <div className="w-full flex flex-row justify-between items-center">
        <div className="flex flex-row justify-between w-full">
          <Link to="/">
            <img
              className={`w-[20vw] transition-all ${scrolled ? "md:w-[5vw]" : "md:w-[7vw]"}`}
              src={LOGO_IMG}
              alt="logo"
            />
          </Link>

          {/* desktop navigation */}
          <div className="uppercase justify-between items-center flex-row hidden md:flex">
            {menuItems}
          </div>
        </div>

        {/* tablet navigation */}
        <div
          className={`uppercase justify-center items-center flex-col flex fixed top-0 bg-opacity-80 transition-all duration-700 w-full h-screen bg-black md:hidden
            ${isActive ? "right-0" : "-right-full"}
        `}
        >
          {menuItems}
        </div>

        {/* burger */}
        <Burger isActive={isActive} setIsActive={setIsActive} />
      </div>
    </Container>
  );
}
