import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SocialIcons from "../SocialIcons/SocialIcons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
  return (
    <div
      id="footer"
      className="bg-none uppercase flex flex-col justify-center items-center text-center p-5"
    >
      {/* <span className="">
        <FontAwesomeIcon className="mr-[2vh]" icon={faPhone} /> (832) 219-7639{" "}
      </span> */}
      <div className="">
        {/* <SocialIcons /> */}
        <span className=" tracking-widest">© NIKO BRIM 2024</span>
      </div>
    </div>
  );
}
