import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Home, Merch, PageNotFound, Releases, Videos } from "../app/index";
import useRouteService from "../Hooks/useRouteService";
import { RoutePattern } from "./RoutePattern";
import Tour from "../app/Home/Tour/Tour";

export default function AppRoutes() {
  const location = useLocation();
  const { setCurrentRoute } = useRouteService();

  useEffect(() => {
    setCurrentRoute(window.location.pathname);

    setTimeout(() => {
      window.scroll({ top: 0 });
    }, 100);
  }, [location.pathname]);

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path={"/" + RoutePattern.RELEASES} element={<Releases />} />
        <Route path={"/" + RoutePattern.VIDEOS} element={<Videos />} />
        <Route path={"/" + RoutePattern.TOUR} element={<Tour />} />
        <Route path={"/" + RoutePattern.SHOP} element={<Merch />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
