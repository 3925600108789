import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  isRouteChanged: false,
  currentRoute: "/",
  isIOS: false,
};

export const routesSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    setIsRouteChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.isRouteChanged = payload;
    },
    setCurrentRoute: (state, { payload }: PayloadAction<string>) => {
      state.currentRoute = payload;
    },
    setIsIOS: (state, { payload }: PayloadAction<boolean>) => {
      state.isIOS = payload;
    },
  },
});

export const { setIsRouteChanged, setCurrentRoute, setIsIOS } =
  routesSlice.actions;
