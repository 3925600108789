import { RoutePattern } from "../../routes/RoutePattern";
import BG_DESKTOP from "../../assets/images/videos.desktop.webp";
import BG_MOBILE from "../../assets/images/videos.mobile.webp";
import { YoutubePopup } from "../../components";
import { useEffect, useState } from "react";
import { YoutubeItemTypes } from "../../components/YoutubeSlider/types";
import axios from "axios";

const notInPlaylistData: YoutubeItemTypes[] = [
  {
    videoId: "0LcwOTdZGa0",
    title: "HUMMERS (Official Music Video) - (Niko Brim , Kai Ca$h & K Wales)",
  },
  {
    videoId: "vfNs8UKTJSg",
    title:
      "Sunday Service (Official Music Video) - (Niko Brim , Kai Ca$h & K Wales)",
  },
  {
    videoId: "9vS7ge6rV5I",
    title: "NIKO BRIM - NO LIES ft. ASIA GRAVES (OFFICIAL MUSIC VIDEO)",
  },
  {
    videoId: "jHh6FsfQuxQ",
    title: "NIKO BRIM - BANKROLL$ (FT. RAPSODY)",
  },
  {
    videoId: "RzPDiM0dYYg",
    title: "NIKO BRIM x DOMANI - KING OF DIAMONDS (OFFICIAL MUSIC VIDEO)",
  },
];

export default function Videos() {
  const [data, setYoutubeItems] = useState<YoutubeItemTypes[]>([]);
  const [currentId, setCurrentId] = useState<number | null>(null);

  useEffect(() => {
    //   get the youtube playlist
    handleRequest();
  }, []);

  async function handleRequest() {
    const playlist_id = "OLAK5uy_nPgBGIt8vfm6rDL6yLXOP5XJc-du_jAVg";
    const youtube_api = "AIzaSyC4zXJfbIVic3KmZgIKdjcUIdga_oBiGeQ";
    const video_list = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlist_id}&fields=items(snippet(resourceId%2FvideoId%2Cthumbnails%2Fmedium%2Furl%2Ctitle))&key=${youtube_api}`;

    const { data } = await axios.get(video_list);

    const filteredData: YoutubeItemTypes[] = [...notInPlaylistData];

    const toBeRemoved = [
      "SPIRIT",
      "MAKE IT HOME (INTERLUDE)",
      "HONEY",
      "CUT THE LIGHTS",
      "CHANGE",
      "KEY OF LIFE",
      "DIE A LEGEND",
      "THE MAN (INTERLUDE)",
      "HUEMAN",
      "280 BENZ",
    ];

    data.items.filter((item: any) => {
      const title = item.snippet.title;

      if (toBeRemoved.includes(title)) return;

      filteredData.push({
        videoId: item.snippet.resourceId.videoId,
        title: item.snippet.title,
      });
    });

    setYoutubeItems(filteredData);
  }

  return (
    <div
      id={RoutePattern.VIDEOS}
      className="text-dark relative flex flex-col justify-center items-center"
    >
      <div className="w-full h-screen md:h-[70vh] relative">
        <img
          className="object-cover object-top w-full h-full"
          src={window.innerWidth >= 768 ? BG_DESKTOP : BG_MOBILE}
          alt="desktop"
        />
        <h3 className="position-center heading-3">VIDEOS</h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 py-[7rem]  mx-auto w-5/6 md:w-3/4">
        {data.map((item, idx) => (
          <div
            key={idx}
            className="flex flex-col p-[1rem] gap-[1rem] mx-auto h-full w-full cursor-pointer border-[.2rem] border-white hover:border-primary"
            onClick={() => {
              setCurrentId(idx);
            }}
          >
            <div className="w-full">
              <img
                className="w-full h-[20vh] md:h-[17vh] object-cover"
                src={`https://i3.ytimg.com/vi/${item.videoId}/hqdefault.jpg`}
                alt="title"
              />
            </div>
            <div className="relative w-full h-full flex flex-col justify-center items-center text-center">
              <span className="uppercase overflow-hidden text-ellipsis whitespace-nowrap block w-full">
                {item.title}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Popup */}
      <YoutubePopup
        currentId={currentId}
        setCurrentId={setCurrentId}
        data={data}
      />
    </div>
  );
}
