import { useEffect, useState } from "react";
import Swal, { SweetAlertOptions } from "sweetalert2";
import $ from "jquery";

export function success({ ...props }: SweetAlertOptions) {
  Swal.fire({
    icon: "success",
    ...props,
  });
}

export function error({ ...props }: SweetAlertOptions) {
  console.log("error");
  Swal.fire({
    icon: "error",
    titleText: "Error!",
    ...props,
  });
}

interface SubmitFormProps {
  email: string;
}

const useEmailSubscribe = () => {
  async function submitForm({ email }: SubmitFormProps) {
    if (!email) {
      error({
        titleText: "Please input an email",
      });
      return false;
    }

    const endpoint =
      "https://script.google.com/macros/s/AKfycbwdg1trQ753sIYQvFX3dOM3peY0LyqvBz4cMc0V2XnZdwPJA52c1Qqgs07U5IL8lPud/exec";

    const usersIP = await getUsersIp();

    const submitted = await $.ajax(endpoint, {
      data: {
        date: getCurrentDate(),
        ip: usersIP.length > 1 ? usersIP : "0.0.0.0",
        email: email,
      },
    });

    return submitted.result === "success";
  }

  function getCurrentDate() {
    const date = new Date().toLocaleDateString("en-US", {
      timeZone: "America/New_York",
    });
    const time = new Date().toLocaleTimeString("en-US", {
      timeZone: "America/New_York",
    });
    return `${date} - ${time} (GMT-4)`;
  }

  async function getUsersIp() {
    const ip = fetch("https://api.ipify.org/?format=json")
      .then((res) => res.json())
      .catch((err) => "Can't find IP");

    return ip;
  }

  return { submitForm };
};

export default useEmailSubscribe;
