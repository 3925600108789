import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faApple,
  faDeezer,
  faFacebookF,
  faInstagram,
  faSoundcloud,
  faSpotify,
  faTiktok,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import TIDAL_IMG from "../../assets/icons/tidal-white-icon.webp";

interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isPandora?: boolean;
}

export const data: SocialIconsTypes[] = [
  {
    icon: faInstagram,
    link: "https://www.instagram.com/nikobrim?igsh=ZjVwZ3d1OGhmcGhw&utm_source=qr",
  },
  {
    icon: faApple,
    link: "https://music.apple.com/us/artist/niko-brim/1208084182",
  },
  {
    icon: faYoutube,
    link: "https://www.youtube.com/channel/UCwQRMjSH_qL-v05RiU-m-cw",
  },
  {
    icon: faSpotify,
    link: "https://open.spotify.com/artist/1leUdTmVH8GDaAQIqhvq7r?si=nl8_e4m_Tla71ab0N1M_eQ",
  },
  {
    img: TIDAL_IMG,
    link: "https://tidal.com/browse/artist/8523669",
  },
];
